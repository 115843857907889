import { Injectable, inject } from '@angular/core';
import { DataleanBaseApiService, Parts, SimpleObject } from 'addiction-components';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StaticMedia, Tail, TailType } from '../../../../shared/models/on-boarding/tail.model';

@Injectable({
	providedIn: 'root',
})
export class ObBuilderService {
	private baseApi = inject(DataleanBaseApiService);
	private onboardingUrl = environment.onboardingUrl ? environment.onboardingUrl : undefined;
	private readonly NO_ONBOARDING_URL_ERROR = new Error('Please provide a on-boarding url');

	// ---------------------
	//        TAILS
	// ---------------------
	//TODO: Implement TailType filtering
	getTails(filters?: { uuids?: string[]; type: TailType }, locale?: string): Observable<Tail[]> {
		if (this.onboardingUrl) {
			const additionalParams: SimpleObject = {};

			additionalParams['sortBy'] = 'name1#asc';
			if (locale) additionalParams['locale'] = locale;

			if (filters && filters.uuids && filters.uuids.length) additionalParams['uuids'] = filters.uuids.join(',');
			if (filters && filters.type) additionalParams['type'] = filters.type;

			//console.log('additionalParams', additionalParams);
			return this.baseApi.getMany<Tail>(this.onboardingUrl + 'tail', [Parts.PREVIEW_ASSET_INFO], {
				additionalParams,
			});
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	getTail(uuid: string, locale?: string) {
		if (this.onboardingUrl) {
			return this.baseApi.getOne<Tail>(this.onboardingUrl + 'tail', uuid, [Parts.CHILDREN], { locale });
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}
	getParentTail(uuid: string) {
		return this.baseApi.getEntities<Tail[]>(this.onboardingUrl + 'tail', { childTailsUUID: uuid }, []);
	}
	updateTail(tail: Tail): Observable<Tail> {
		if (this.onboardingUrl) {
			const { uuid } = tail;
			return this.baseApi.updateOne<Tail>(this.onboardingUrl + 'tail', uuid, tail, [Parts.EMPTY]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}
	updatePosition(uuid: string, position: number[]): Observable<Tail> {
		if (this.onboardingUrl) {
			return this.baseApi.patchOne<Tail>(this.onboardingUrl + 'tail', uuid, { position });
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}
	createTail(tail: Tail): Observable<Tail> {
		if (this.onboardingUrl) {
			return this.baseApi.createOne<Tail>(this.onboardingUrl + 'tail', tail, [Parts.EMPTY]);
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	deleteTail(uuid: string): Observable<true> {
		if (this.onboardingUrl) {
			return this.baseApi.deleteOne(this.onboardingUrl + 'tail', uuid).pipe(map(() => true));
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}

	// STATIC MEDIA
	getStaticMedia(tailType: string): Observable<StaticMedia[]> {
		if (this.onboardingUrl) {
			const additionalParams: SimpleObject = {};
			if (tailType) additionalParams['type'] = tailType;
			return this.baseApi.getMany<StaticMedia>(this.onboardingUrl + 'static-media', [], {
				additionalParams,
			});
		} else {
			throw this.NO_ONBOARDING_URL_ERROR;
		}
	}
}
